import React from 'react';
import './App.scss';
import styled from 'styled-components/macro';
import logo from './assets/images/white_logo_transparent_background.png';
import img from './assets/images/full_page_image.jpg';


const Main = styled.div`
  position:relative;
  height:100vh;
  background-image:url(${img});
  background-repeat:no-repeat;
  background-position:center;
  background-size:cover;
`
const Body = styled.div`
  position:absolute;
  bottom:100px;
  left:50%;
  right:50%;
  grid-area:body;
  background:black;
  opacity:0.7;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
`


function App() {
  return (
    <Main>
      <Body>
        <div css='background:black;text-align:center;box-shadow:0 0 20px 10px white;border-radius:15%;'>
        <img src={logo} height='150px;' alt='Musatec Logo' />
        <h1 css='background:#7bb062;padding:10px;color:white;'>Proximamente...</h1>

        </div>
      </Body>
    </Main>
  );
}

export default App;
